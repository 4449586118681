// CSS Style
import 'admin/styles/site.scss'
import 'admin/styles/style.min.css'
import 'admin/styles/custom.scss'
import 'styles/public'

// Other JS
import('admin/site')
import 'admin/notification'

import Rails from "@rails/ujs"
import 'public/base'

Rails.start();

window.jQuery = $;
window.$ = $;
